export function loadLocalStorageState<TModel>(key: string, defaultState?: Partial<TModel>): Partial<TModel> | undefined {
    // Ничего не делаем во время SSR
    if (typeof window === 'undefined') {
        return;
    }

    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState === null) {
            return defaultState;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.warn('Failed to load state from localStorage with key %s.', key, err);
        return defaultState;
    }
}

export function saveLocalStorageState<TModel>(key: string, state: Partial<TModel>) {
    // Ничего не делаем во время SSR
    if (typeof window === 'undefined') {
        return;
    }

    try {
        if (state === undefined) {
            localStorage.removeItem(key);
            return;
        }

        const serializedState = JSON.stringify(state);
        localStorage.setItem(key, serializedState);
    } catch (err) {
        console.warn('Failed to save state to localStorage.', err);
    }
}
