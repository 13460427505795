import {
    call,
    fork,
    put,
    select,
    takeLatest,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {
    ActionCreatorType,
    createObjectReducer,
    createUpdateAction,
} from '@core/store/actions';
import { createType } from './core';
import {
    IBaseHandler,
    SimpleSelector,
} from './props';
import { safe } from '../utils/globalUtils';
import { safeSaga } from './utils';

/**
 * Хендлер для управления данных во внешнем хранилище.
 * Позволяет читать и обновлять данные в хранилище.
 *
 * @category Duck Handlers
 */
export interface IStoreHandler<TModel> extends IBaseHandler {
    /**
     * Селектор для получения данных.
     * @group Selectors
     */
    selector: SimpleSelector<TModel>;
    /**
     * @event
     * Срабатывает во время обновления данных.
     */
    UPDATE_ACTION: string;
    /**
     * Действие для обновления данных в хранилище.
     * Используете `undefined` чтобы сбросить данные в начальное состояние.
     */
    update: ActionCreatorType<TModel>;
}

/**
 * Базовые настройки хендлера.
 */
export interface IStoreHandlerBasicConfig<TModel> {
    /**
     * Функция для получения начального состояния.
     */
    getInitialState?: () => Partial<TModel>;
    /**
     * Флаг отладки данных.
     */
    echo?: boolean;
}

/**
 * Базовые настройки управления хранилищем.
 */
export interface IStoreHandlerPersistConfig<TModel> {
    /**
     * Событие для начальной загрузки данных из хранилища.
     */
    onLoad?: () => Promise<Partial<TModel> | undefined>|Partial<TModel> | undefined;
    /**
     * Событие для сохранения данных в хранилище.
     */
    onSave?: (data: Partial<TModel>) => Promise<void>|void;
}

export interface IStoreHandlerConfig<TModel> extends IStoreHandlerBasicConfig<TModel>, IStoreHandlerPersistConfig<TModel> {

}

/**
 * Абстрактная модель хранения данных.
 * С помощью конфигурации позволяет реализовать хранение данных во внешнем хранилище с помощью событий `onSave` и `onLoad`.
 *
 * Без указания настроек `onSave` и `onLoad` хендлер будет вести себя как обычный слайс доступа к данным в Redux.
 *
 * @param prefix Префикс duck модуля
 * @param name Имя хендлера внутри модуля
 * @param config Дополнительные настройки хендлера
 * @returns Новый экземпляр управления хранилищем данных
 *
 * @category Duck Handlers
 *
 * @see {@link useStoreHandler}
 * @see {@link localStoreHandler}
 * @see {@link sessionStoreHandler}
 * @see {@link asyncStorageStoreHandler}
 */
export function storeHandler<TModel = any>(prefix: string, name: string, config: IStoreHandlerConfig<TModel> = {}): IStoreHandler<TModel> {
    const id = `${prefix}/${name}`;
    const actionName = name.toUpperCase();

    const UPDATE_ACTION = createType(prefix, `UPDATE_${actionName}`);
    const update = createUpdateAction<TModel>(UPDATE_ACTION);

    const getDuck = state => state[prefix];
    const selector = createSelector<any, TModel>(getDuck, data => data[name] as TModel);

    let cachedLoadedState: Partial<TModel> | undefined;

    const getInitialState = (): Partial<TModel> => {
        const defaultInitialState: TModel = typeof config.getInitialState === 'function'
            ? safe(() => config.getInitialState()) ?? {}
            : {} as TModel;

        return defaultInitialState;
    };

    const reducer = createObjectReducer<Partial<TModel>>(UPDATE_ACTION, getInitialState);
    const reducerInfo = { [name]: reducer };

    function* initialStoreLoadSaga() {
        if (typeof config.onLoad !== 'function') {
            return;
        }

        if (config.echo) {
            if (!cachedLoadedState) {
                console.debug(`STORE ${id} LOADING`, prefix, actionName);
            }
        }

        const loadedState = yield call(config.onLoad);
        if (loadedState) {
            cachedLoadedState = loadedState;
            yield put({
                type: UPDATE_ACTION,
                value: loadedState,
                source: 'onLoad',
            });
        }

        if (config.echo) {
            console.debug(`STORE ${id} LOADED`, prefix, actionName, JSON.stringify(loadedState) ?? '<undefined>');
        }
    }

    function* onUpdateActionSaga({
        type,
        value,
        source,
    }: { type: string; value: Partial<TModel>; source: string }) {
        if (source === 'onLoad') {
            return;
        }

        if (value === undefined) {
            if (config.echo) {
                console.debug(`STORE ${id} CLEAR`);
            }

            cachedLoadedState = null;
            if (typeof config.onSave === 'function') {
                yield call(config.onSave, undefined);
            }

            return;
        }

        const storeState = yield select(selector);
        if (config.echo) {
            console.debug(`STORE ${id} SAVE`, JSON.stringify(storeState) ?? '<undefined>');
        }

        if (typeof config.onSave === 'function') {
            yield call(config.onSave, storeState);
        }
    }

    const effects = [
        takeLatest(UPDATE_ACTION, safeSaga(onUpdateActionSaga, id)),
        fork(safeSaga(initialStoreLoadSaga, id)),
    ];

    return {
        UPDATE_ACTION,
        update,
        selector,
        reducer,
        reducerInfo,
        effects,
    };
}

